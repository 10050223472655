

















































import { Person, Attitude } from '@/interfaces/Person';
import DataViewBase from '@/classes/component-bases/DataViewBase';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GridView extends DataViewBase {
  @Prop()
  private persons!: Person[];

  @Prop()
  private attitudeitems!: Attitude[];

  @Prop()
  private search!: string;

  private headers: object = {
    personen: [
      { text: 'Name', value: 'name', align: 'left' },
      {
        text: 'Gesinnung',
        value: 'attitude',
        align: 'center',
        width: '150px',
      },
      { text: 'Wichtigkeit', value: 'importance', align: 'left' },
      { text: 'Kurzbeschreibung', value: 'description', align: 'left' },
      { text: 'Angetroffen', value: 'time', align: 'left' },
      { text: 'Sonstiges', value: 'misc', align: 'left' },
      { text: 'Aktionen', value: 'actions', width: 150 },
    ],
    orte: [
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Beschreibung', value: 'description', align: 'center' },
      { text: 'Angetroffen', value: 'time', align: 'left' },
    ],
  };

  constructor() {
    super();
  }

  private getImportanceString(importance) {
    return DataViewBase.importanceString(importance);
  }

  private onRowClick(item) {
    this.$emit('focusperson', item);
  }
}
