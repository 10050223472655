


































































































































































































































import { Person } from '@/interfaces/Person';
import gridview from '@/components/world/gridview.vue';
import cardview from '@/components/world/cardview.vue';
import singleview from '@/components/world/single.vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Vue from 'vue';
import DataViewBase from '@/classes/component-bases/DataViewBase';
import { WorldMarker } from '../../interfaces/Marker';
import FirebaseGateway from '../../classes/firebaseGateway';
import 'firebase/storage';
import { mdiMagnify } from '@mdi/js';

interface Attitude {
  text: string;
  value: string;
}

@Component({
  components: { gridview, cardview, singleview },
})
export default class World extends Vue {
  private searchIcon = mdiMagnify;
  private loading: boolean = true;
  private model: number = 0;
  private dialogModel: boolean = false;
  private confirmModel: boolean = false;
  private viewModel: string = 'grid';
  private search: string = '';
  private importanceFilter: number[] = [];
  private tagFilter: string[] = [];
  private onlyEncountered = false;
  private imageLoadError: string | null = null;
  private focusPerson: Person | null = null;
  private attitudeItems: Attitude[] = [
    { text: 'Gut', value: 'good' },
    { text: 'Böse', value: 'evil' },
    { text: 'Neutral', value: 'neutral' },
    { text: 'Unbekannt', value: 'unknown' },
  ];
  private itemToEdit: Person = {
    id: null,
    name: null,
    attitude: null,
    title: null,
    image: null,
    loadedImageUrl: null,
    importance: '1',
    description: null,
    notes: null,
    time: null,
    tags: [],
    location: null,
    encounters: [],
    encountersModel: [],
    encountersModel2019: [],
    statusModel: [],
    private: false,
    deceased: false,
    addedBy: null,
    source: 'group',
  };
  private defaultItem: Person = {
    id: null,
    name: null,
    attitude: null,
    title: null,
    image: null,
    importance: '1',
    description: null,
    notes: null,
    time: null,
    encounters: [],
    tags: [],
    location: null,
    encountersModel: [],
    encountersModel2019: [],
    statusModel: [],
    private: false,
    deceased: false,
    addedBy: null,
    loadedImageUrl: null,
    source: 'group',
  };
  private imageFile: File | null = null;
  private loadedImageUrl: string = '';
  private savingPerson: boolean = false;
  private singleDialogOpen = false;
  private locationsLoading = true;

  public created() {
    this.loadData();
  }

  get dataPersons(): Person[] {
    let persons: Person[] = this.$store.getters.persons;

    if (this.search !== null && this.search !== '' && this.search.length > 2) {
      persons = persons.filter((person) => {
        const searchReg = new RegExp(this.search, 'i');
        if (person.name !== null && person.name.search(searchReg) !== -1) {
          return true;
        } else if (person.description !== null && person.description.search(searchReg) !== -1) {
          return true;
        } else if (person.notes !== null && person.notes.search(searchReg) !== -1) {
          return true;
        }

        return false;
      });
    }

    if (this.importanceFilter.length > 0) {
      persons = persons.filter((person) => {
        const index = this.importanceFilter.findIndex((importance) => {
          return importance + 1 === parseInt(person.importance, 10);
        });
        return index !== -1;
      });
    }

    if (this.onlyEncountered) {
      persons = persons.filter((person) => {
        return person.encounters ? person.encounters.length > 0 : true;
      });
    }

    if (this.tagFilter.length > 0) {
      persons = persons.filter((person) => {
        for (const tag of this.tagFilter) {
          if (tag === 'keine Tags' && (typeof person.tags === 'undefined' || person.tags.length === 0)) {
            return true;
          }

          if (typeof person.tags !== 'undefined' && person.tags.indexOf(tag) !== -1) {
            return true;
          }
        }
        return false;
      });
    }

    return persons;
  }

  get totalDataSize() {
    return this.$store.getters.persons.length;
  }

  get dialogTitle(): string {
    if (this.itemToEdit.id === null) {
      return 'Neues Element hinzufügen';
    } else {
      return 'Element bearbeiten';
    }
  }

  get itemToEditEncountersModel(): number[] {
    const model = this.itemToEdit.encountersModel;
    if (typeof model === 'undefined' || model === null) {
      return [];
    }
    return model;
  }

  @Watch('itemToEditEncountersModel')
  private onEncountersChanged(newValue: number[], oldValue: number[]) {
    const encountersTransformed: string[] = [];
    newValue.forEach((encounterIndex) => {
      encountersTransformed.push('2018-' + (encounterIndex + 1));
    });

    this.itemToEdit.encounters = encountersTransformed;
  }

  get itemToEditEncountersModel2019(): number[] {
    const model = this.itemToEdit.encountersModel;
    if (typeof model === 'undefined' || model === null) {
      return [];
    }
    return model;
  }

  get allTags(): string[] {
    const allTags = this.$store.getters.personTags;
    let allTagNames: string[] = [];
    for (const tag in allTags) {
      if (!allTags[tag]) {
        continue;
      }
      allTagNames.push(tag);
    }
    allTagNames = allTagNames.sort();
    return allTagNames;
  }

  get allTagsMap(): object[] {
    const map: object = this.$store.getters.personTags;
    const mapTransformed: object[] = [];
    for (const tag in map) {
      if (!map[tag]) {
        continue;
      }
      mapTransformed.push({
        text: tag + ' (' + map[tag] + ')',
        value: tag,
      });
    }

    return mapTransformed;
  }

  get allLocations() {
    const mapMarkers: WorldMarker[] = JSON.parse(JSON.stringify(this.$store.getters.markers));
    return mapMarkers.sort((markerA, markerB) => (markerA.name > markerB.name ? 1 : -1));
  }

  @Watch('itemToEditEncountersModel2019')
  private onEncountersChanged2019(newValue: number[], oldValue: number[]) {
    const encountersTransformed: string[] = [];
    newValue.forEach((encounterIndex) => {
      encountersTransformed.push('2019-' + (encounterIndex + 1));
    });

    this.itemToEdit.encounters = encountersTransformed;
  }

  private getData(key): object[] {
    switch (key) {
      case 'personen':
        return this.dataPersons;
      default:
        return [];
    }
  }

  private importanceString(importance) {
    return DataViewBase.importanceString(importance);
  }

  private editItem(item: Person) {
    if (!item.hasOwnProperty('encounters')) {
      item.encounters = [];
    }
    if (!item.hasOwnProperty('encountersModel')) {
      item.encountersModel = [];
    }

    this.itemToEdit = Object.assign({}, item);
    this.dialogModel = true;
  }

  private closeDialog() {
    this.dialogModel = false;
    setTimeout(() => {
      this.itemToEdit = Object.assign({}, this.defaultItem);
    }, 300);
  }

  private openNewPersonsDialog() {
    this.itemToEdit = Object.assign({}, this.defaultItem);
  }

  /**
   * Saves the item. If an image is selected, first tries to upload
   * it and if successful, saves the whole person.
   */
  private saveItem() {
    this.savingPerson = true;

    const currentWorld = this.$store.getters.currentWorld;
    if (!currentWorld) {
      throw new Error('Currentworld is not set');
    }

    // In case it has an image, first upload that
    if (this.itemToEdit.image !== null && this.imageFile !== null) {
      const firebaseGateway = new FirebaseGateway();
      firebaseGateway
        .uploadFile(this.imageFile, '/worlds/' + currentWorld + '/persons/')
        .then((downloadUrl) => {
          this.itemToEdit.loadedImageUrl = downloadUrl;
          this.savePersonToFirebase();
        })
        .catch((error) => {
          this.imageLoadError = error;
          this.savingPerson = false;
        });
    } else {
      this.savePersonToFirebase();
    }
  }

  /**
   * Saves the actual person to firebase firestore
   */
  private savePersonToFirebase() {
    this.$store
      .dispatch('saveWorldPerson', this.itemToEdit)
      .then(() => {
        this.itemToEdit = Object.assign({}, this.defaultItem);
        this.savingPerson = false;
        this.closeDialog();
      })
      .catch((error) => {
        this.savingPerson = false;
      });
  }

  private deleteItem(id) {
    this.$store.dispatch('deletePerson', id).then(() => {
      this.confirmModel = false;
    });
  }

  private confirmDeletion(item) {
    this.itemToEdit = item;
    this.confirmModel = true;
  }

  private loadData() {
    this.$store.dispatch('loadPersons', this.$store.getters.currentGroup).then(() => {
      this.loading = false;
    });

    this.$store.dispatch('loadWorldMapMarkers').then(() => {
      this.locationsLoading = false;
    });
  }

  private setFocusPerson(person: Person) {
    this.focusPerson = person;
    this.singleDialogOpen = true;
  }

  private onSingleDialogClosed() {
    this.focusPerson = null;
    this.singleDialogOpen = false;
  }

  private pickFile() {
    const input = this.$refs.image as HTMLElement;
    input.click();
  }

  private onFilePicked(e) {
    const files = e.target.files;
    if (files[0] !== undefined && files[0] !== null) {
      this.itemToEdit.image = files[0].name as string;
      if (this.itemToEdit.image.lastIndexOf('.') <= 0) {
        return;
      }
      this.loadedImageUrl = '';
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.loadedImageUrl = fr.result as string;
        this.imageFile = files[0];
      });
    } else {
      this.itemToEdit.image = null;
      this.imageFile = null;
      this.loadedImageUrl = '';
    }
  }

  private onRemoveImage() {
    this.itemToEdit.image = null;
    this.loadedImageUrl = '';
    this.imageFile = null;
  }
}
