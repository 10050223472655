var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"text-align":"left"},attrs:{"headers":_vm.headers.personen,"items":_vm.persons},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.attitude",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getChipColor(item.attitude)}},[_vm._v(" "+_vm._s(_vm.getAttitudeTranslated(item.attitude, _vm.attitudeitems))+" ")])]}},{key:"item.importance",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"importance",class:'importance-' + item.importance},on),[_c('div',{staticClass:"importance--value"}),_c('div',{staticClass:"importance--value"}),_c('div',{staticClass:"importance--value"}),_c('div',{staticClass:"importance--value"}),_c('div',{staticClass:"importance--value"})])]}}],null,true)},[_c('span',[_vm._v("Geschätzte Wichtigkeit: "+_vm._s(_vm.getImportanceString(parseInt(item.importance))))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"action-column"},[_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'edit']}})],1),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeletion(item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1)],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("Die Suche nach \""+_vm._s(_vm.search)+"\" ergab keine Ergebnisse.")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }